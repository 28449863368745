import React, { useState } from "react";
import RemoteBricksLogo from "../assets/images/RemoteBricksLogos/RemoteBricksLogo-light.svg";
import { NavLink } from "react-router-dom";
import GetInTouchDialogue from "./GetInTouchDialogue";

const Header = () => {
  const [isShowContactUs, setContactUsVisible] = useState<boolean>(false);
  const [isShowMobileNav, setMobileNavVisible] = useState<boolean>(false);

  document.addEventListener("click", () => setMobileNavVisible(false));

  return (
    <>
      <header className="main-header">
        <div className="container h-100">
          <div className="row align-items-center h-100">
            <div className="col-lg-4 col-5">
              <div className="brand-logo-container">
                <img
                  src={RemoteBricksLogo}
                  className="brand-logo"
                  alt="Remotebricks Logo"
                />
              </div>
            </div>
            <div className="col-lg-8 col-7">
              <nav className="navigation mobile-navigation">
                <ul className="navbar justify-content-end gap-2">
                  <li className="nav-item">
                    <button
                      onClick={() => setContactUsVisible(true)}
                      className="btn btn-border nav-button"
                    >
                      <span>Get in touch</span>
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        setMobileNavVisible(!isShowMobileNav);
                      }}
                      className="btn btn-border nav-button border-0 p-2"
                    >
                      <span className="bi bi-list fs-2"></span>
                    </button>
                  </li>
                </ul>
              </nav>

              <nav
                className={`navigation desktop-nav ${
                  isShowMobileNav ? "expand" : ""
                }`}
              >
                <ul className="navbar">
                  <li className="nav-item">
                    <span className={"nav-link"} style={{cursor:"pointer"}} onClick={() => {
                      window.location.href = '/';                      
                    }}>
                      Home
                    </span>
                  </li>

                  {/* 
                    <li className='nav-item'>
                      <NavLink className={'nav-link'} to='/'>Solutions</NavLink>
                    </li> *
                    <li className='nav-item'>
                      <NavLink className={'nav-link'} to='/'>Technologies</NavLink>
                      <div className="sub-nav mega-menu">
                          <div className="menu-wrapper">
                              <ul>
                                  <li>
                                      <NavLink className={'sub-nav-link'} to='/'>
                                          <h6 className='mb-0'>Menu 1</h6>
                                          <small className='mb-0'>Sub-menu</small>
                                      </NavLink>
                                  </li>
                                  <li>
                                      <NavLink className={'sub-nav-link'} to='/'>
                                          <h6 className='mb-0'>Menu 1</h6>
                                          <small className='mb-0'>Sub-menu</small>
                                      </NavLink>
                                  </li>
                                  <li>
                                      <NavLink className={'sub-nav-link'} to='/'>
                                          <h6 className='mb-0'>Menu 1</h6>
                                          <small className='mb-0'>Sub-menu</small>
                                      </NavLink>
                                  </li>
                                  <li>
                                      <NavLink className={'sub-nav-link'} to='/'>
                                          <h6 className='mb-0'>Menu 1</h6>
                                          <small className='mb-0'>Sub-menu</small>
                                      </NavLink>
                                  </li>
                                  <li>
                                      <NavLink className={'sub-nav-link'} to='/'>
                                          <h6 className='mb-0'>Menu 1</h6>
                                          <small className='mb-0'>Sub-menu</small>
                                      </NavLink>
                                  </li>
                                  <li>
                                      <NavLink className={'sub-nav-link'} to='/'>
                                          <h6 className='mb-0'>Menu 1</h6>
                                          <small className='mb-0'>Sub-menu</small>
                                      </NavLink>
                                  </li>
                              </ul>
                          </div>
                      </div>
                  </li> 
                  <li className="nav-item">
                    <NavLink className={"nav-link"} to="/">
                      Case Studies
                    </NavLink>
                  </li>
                */}
                  <li className="nav-item">
                    <NavLink className={"nav-link"} to="/aboutus">
                      About us
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <button
                      onClick={() => setContactUsVisible(true)}
                      className="btn btn-border nav-button"
                    >
                      <span>Get in touch</span>
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </header>

      {isShowContactUs && (
        <GetInTouchDialogue
          onclose={() => setContactUsVisible(false)}
        ></GetInTouchDialogue>
      )}
    </>
  );
};

export default Header;
