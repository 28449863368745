import React, { useCallback, useEffect } from "react";

const Statistics = () => {
  const counter = useCallback(
    (id: string, start: number, end: number, duration: number) => {
      let obj = document.getElementById(id);
      if (obj && !obj.classList.contains("count-complete")) {
        let current = start,
          range = end - start,
          increment = end > start ? 1 : -1,
          step = Math.abs(Math.floor(duration / range)),
          timer = setInterval(() => {
            current += increment;
            if (obj) obj.textContent = current.toString();
            if (current === end) {
              clearInterval(timer);
            }
          }, step);
      }
      obj?.classList.add("count-complete");
    },
    []
  );

  const isInViewport = useCallback((id: string) => {
    const element = document.getElementById(id) as HTMLElement;
    // Get the bounding client rectangle position in the viewport

    if (element) {
      let bounding = element.getBoundingClientRect();
      // Checking part. Here the code checks if it's *fully* visible
      // Edit this part if you just want a partial visibility
      if (
        bounding.top >= 0 &&
        bounding.left >= 0 &&
        bounding.right <=
          (window.innerWidth || document.documentElement.clientWidth) &&
        bounding.bottom <=
          (window.innerHeight || document.documentElement.clientHeight)
      ) {
        console.log("In the viewport! :)");
        return true;
      } else {
        // console.log('Not in the viewport. :(');
        return false;
      }
    }
  }, []);

  useEffect(() => {
    window.onscroll = () => {
      if (isInViewport("count-statistics")) {
        counter("clients", 0, 100, 2000);
        counter("team", 0, 20, 2000);
        counter("projects", 0, 80, 2000);
        counter("blogs", 0, 100, 2000);
      }
    };
  }, []);
  return (
    <section className="section statistics-section">
      <div className="container statistics-container">
        <div className="section-heading text-center mb-5">
          <h5 className="fw-reg mb-2">Here are some</h5>
          <h3 className="fw-sbo">Statistics</h3>
        </div>
        <div className="row g-4" id="count-statistics">
          <div className="col-xl-3 col-lg-4 col-6">
            <div className="statistics h-100 d-flex flex-column justify-content-between">
              <h2 className="fw-sbo">
                <span id="clients"></span>%
              </h2>
              <h5 className="fw-reg">Satisfied clients</h5>
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 col-6">
            <div className="statistics h-100 d-flex flex-column justify-content-between">
              <h2 className="fw-sbo">
                <span id="projects"></span>+
              </h2>
              <h5 className="fw-reg">Finished Project</h5>
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 col-6">
            <div className="statistics h-100 d-flex flex-column justify-content-between">
              <h2 className="fw-sbo">
                <span id="team"></span>+
              </h2>
              <h5 className="fw-reg">Team Members</h5>
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 col-6">
            <div className="statistics h-100 d-flex flex-column justify-content-between">
              <h2 className="fw-sbo">
                <span id="blogs"></span>+
              </h2>
              <h5 className="fw-reg">Blog Posts</h5>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Statistics;
