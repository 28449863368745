import React from "react";

import BilltrimImage from "../../assets/images/Clients/billtrim.png";
import AccubitsImage from "../../assets/images/Clients/accubits.png";
import EncoraImage from "../../assets/images/Clients/encora.png";
import AirbillsImage from "../../assets/images/Clients/Airbills.png";
import NewtonImage from "../../assets/images/Clients/Newton.png";
import DexterImage from "../../assets/images/Clients/Dexter.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const Clients = () => {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 5,
    pauseOnHover: false,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    speed: 4000,
    autoplaySpeed: 0,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.6,
          slidesToScroll: 1.6,
        },
      },
    ],
  };

  return (
    <section className="section clients-section">
      <div className="clients-container">
        <div className="section-heading text-center mb-5">
          <h5 className="fw-reg mb-2">Our</h5>
          <h3 className="fw-sbo">Clients</h3>
        </div>
        <div className="clients-wrapper g-4">
          <div className="client-brand-img">
            <Slider {...settings}>
              <div>
                <img
                  src={BilltrimImage}
                  alt="BilltrimImage"
                  className="client-logo BilltrimImage"
                />
              </div>
              <div>
                <img
                  src={AccubitsImage}
                  alt="AccubitsImage"
                  className="client-logo AccubitsImage"
                />
              </div>
              <div>
                <img
                  src={EncoraImage}
                  alt="EncoraImage"
                  className="client-logo EncoraImage"
                />
              </div>
              <div>
                <img
                  src={AirbillsImage}
                  alt="AirbillsImage"
                  className="client-logo AirbillsImage"
                />
              </div>
              <div>
                <img
                  src={NewtonImage}
                  alt="NewtonImage"
                  className="client-logo NewtonImage"
                />
              </div>
              <div>
                <img
                  src={DexterImage}
                  alt="DexterImage"
                  className="client-logo DexterImage"
                />
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Clients;
