import React, { useEffect, useRef, useState } from "react";

interface DialogueProps {
  onclose?: () => void;
}

const GetInTouchDialogue: React.FC<DialogueProps> = (props: DialogueProps) => {
  const { onclose = () => {} } = props;

  const [isShowDialogue, setShowDialogue] = useState(false);
  const dialogueFadeIn = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    console.log("dialogue open");
    dialogueFadeIn.current = setTimeout(() => {
      setShowDialogue(true);
    }, 100);

    return () => {
      if (dialogueFadeIn.current) clearTimeout(dialogueFadeIn.current);
    };
  }, []);

  return (
    <div className="dialogue-wrapper">
      <div
        onClick={() => {
          setShowDialogue(false);
          setTimeout(() => onclose(), 200);
        }}
        className={`dialogue-overlay ${isShowDialogue ? "fadeIn" : ""}`}
      ></div>
      <div className={`dialogue ${isShowDialogue ? "fadeIn" : ""}`}>
        <div className="dialogue-header">
          <h5 className="mb-0">Contact us</h5>

          <div className="close-dialogue">
            <button
              type="button"
              className="btn-close"
              onClick={() => {
                setShowDialogue(false);
                setTimeout(() => onclose(), 200);
              }}
              aria-label="Close"
            >
              <i className="bi bi-x"></i>
            </button>
          </div>
        </div>
        <div className="dialogue-body">
          <div className="contact-form">
            <form>
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Name
                </label>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  id="name"
                  aria-describedby="emailHelp"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  Email address
                </label>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  id="email"
                  aria-describedby="emailHelp"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="phone" className="form-label">
                  Phone number
                </label>
                <input
                  type="number"
                  className="form-control form-control-lg"
                  id="phone"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="companeyDetails" className="form-label">
                  Companey Name or Website
                </label>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  id="companeyDetails"
                  aria-describedby="emailHelp"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="projectDescription" className="form-label">
                  Describe your Project
                </label>
                <textarea
                  className="form-control textarea"
                  id="projectDescription"
                  rows={3}
                ></textarea>
              </div>
            </form>
          </div>
        </div>
        <div className="dialogue-footer">
          <button className="btn btn-outline-secondary">Cancel</button>
          <button className="btn btn-primary">Submit</button>
        </div>
      </div>
    </div>
  );
};

export default GetInTouchDialogue;
