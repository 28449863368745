import React from "react";
import { ReactComponent as VisionIcon } from "../../assets/icons/vision.svg";
import { ReactComponent as MissionIcon } from "../../assets/icons/mission.svg";

const About = () => {
  return (
    <div className="aboutus-page">
      <div className="page-banner">
        <div className="container">
          <h1 className="banner-title">
            About us<span>.</span>
          </h1>
        </div>
      </div>
      <div className="page-content">
        <div className="container">
          <div className="greeting-text">
            <h4>Welcome to RemoteBricks!</h4>
            <p>
              At RemoteBricks, we’re passionate about harnessing the power of
              technology to drive business success. Established with a vision to
              revolutionize the digital landscape, our company stands at the
              forefront of innovation, dedicated to empowering businesses
              through cutting-edge solutions and unparalleled expertise.
            </p>
            <p>
              Our team of skilled professionals is committed to fostering growth
              and transformation. We believe that technology should be a
              catalyst for progress, not a barrier. By combining deep industry
              knowledge with a forward-thinking approach, we help our clients
              navigate the complexities of the digital world with confidence.
            </p>
          </div>

          <div className="our-story">
            <h4 className="text-center mb-5">Our Story</h4>
            <div className="story-tree-wrapper">
              <div className="list-stories">
                <div className="story-item">
                  <div className="thumbnail">
                    <img
                      src="https://i.pinimg.com/564x/3c/e9/ce/3ce9ce72abb36c9e3397be95cbfdd1e1.jpg"
                      alt="2021"
                    />
                  </div>
                  <div className="story-content">
                    <h6>2017</h6>
                    <p>
                      It all began with a simple idea and a passion for
                      innovation. A small team gathered to brainstorm solutions,
                      setting the foundation for what would become a
                      transformative journey.
                    </p>
                  </div>
                </div>
                <div className="story-item">
                  <div className="thumbnail">
                    <img
                      src="https://i.pinimg.com/564x/c6/1b/cf/c61bcfc3eaa96e651a1eae57c6b60553.jpg"
                      alt="2017"
                    />
                  </div>
                  <div className="story-content">
                    <h6>2021</h6>
                    <p>
                      Recognizing the power of flexibility, we transitioned to a
                      fully remote company. This shift not only broadened our
                      talent pool but also fostered a dynamic an d diverse work
                      culture that prioritized collaboration and creativity.
                    </p>
                  </div>
                </div>
                <div className="story-item">
                  <div className="thumbnail">
                    <img
                      src="https://i.pinimg.com/564x/45/1b/8d/451b8d20bf702d3312f207ba08e71f51.jpg"
                      alt="2021"
                    />
                  </div>
                  <div className="story-content">
                    <h6>2023</h6>
                    <p>
                      Building on our momentum, we opened offices in multiple
                      locations. This expansion allowed us to better serve our
                      clients and strengthened our team’s connections across
                      different regions.
                    </p>
                  </div>
                </div>
                <div className="story-item">
                  <div className="thumbnail">
                    <img
                      src="https://i.pinimg.com/564x/ab/59/63/ab5963fca54c755c399d1fed0540d133.jpg"
                      alt="2021"
                    />
                  </div>
                  <div className="story-content">
                    <h6>2024</h6>
                    <p>
                      This year, we rebranded to reflect our evolution and
                      ambitions. From starting with just two employees working
                      in a living room to now operating as a full-fledged
                      organization, we’ve come a long way. Our journey is a
                      testament to our resilience, innovation, and commitment to
                      excellence.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="vision-mission">
            <h4 className="text-center mb-5">Our Vision & Mission</h4>
            <div className="row g-4">
              <div className="col-md-6">
                <div className="card ">
                  <div className="card-body">
                    <div className="img-wrapper">
                      {/* <img
                        src="https://i.pinimg.com/736x/26/86/1c/26861ce966c34c51445b5bcdd2a7d1ee.jpg"
                        alt="VisionMissionImage"
                      /> */}
                      <VisionIcon />
                    </div>
                    <div className="vision">
                      <div className="service-title">
                        <h6 className="mb-0 fw-sbo">Vision</h6>
                      </div>
                      <div className="description">
                        {/* <h6 className="mb-2 fw-med">Project Strategy & User experience</h6> */}
                        <p>
                          “RemoteBricks aspires to be a beacon of technological
                          advancement, setting new standards and inspiring
                          businesses to embrace the future with confidence and
                          agility.”
                        </p>
                        {/* <p className='view-more-link'><Link to={'/'} className='d-flex align-items-end'>Hire us <i className='bi bi-arrow-right-short fs-5'></i></Link></p> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card ">
                  <div className="card-body">
                    <div className="img-wrapper">
                      {/* <img
                        src="https://i.pinimg.com/564x/d4/a9/39/d4a939e5c1d123e008c481a472b581b2.jpg"
                        alt="VisionMissionImage"
                      /> */}
                      <MissionIcon />
                    </div>
                    <div className="mission">
                      <div className="service-title">
                        <h6 className="mb-0 fw-sbo">Mission</h6>
                      </div>
                      <div className="description">
                        {/* <h6 className="mb-2 fw-med">Project Strategy & User experience</h6> */}
                        <p>
                          “At RemoteBricks, our mission is to drive innovation
                          and excellence in the digital world.”
                        </p>
                        {/* <p className='view-more-link'><Link to={'/'} className='d-flex align-items-end'>Hire us <i className='bi bi-arrow-right-short fs-5'></i></Link></p> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="our-values">
                      <h4>Our Values:</h4>
                      <ul>
                        <li>
                          <h6>Innovation:</h6>
                          <p>
                            We continually explore new technologies and
                            methodologies to stay ahead of the curve and deliver
                            groundbreaking solutions.
                          </p>
                        </li>
                        <li>
                          <h6>Excellence:</h6>
                          <p>
                            We are dedicated to the highest standards of
                            quality, ensuring that our work consistently exceeds
                            expectations.
                          </p>
                        </li>
                        <li>
                          <h6>Collaboration:</h6>
                          <p>
                            We value strong, collaborative relationships with
                            our clients, working together to achieve shared
                            goals and drive success.
                          </p>
                        </li>
                        <li>
                          <h6>Integrity:</h6>
                          <p>
                            We operate with transparency and honesty, building
                            trust through every interaction and decision.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="our-commitment">
            <h4>Our Commitment</h4>
            <p>
              <q>
                At RemoteBricks, we are more than just a service provider; we
                are a trusted partner in your journey toward digital excellence.
                Our commitment is to understand your unique challenges and
                aspirations, crafting tailored solutions that align with your
                vision and objectives. We invite you to explore how RemoteBricks
                can be a key driver in your digital transformation. Together,
                let’s build the future of technology. Connect with us to learn
                more about how we can support your business and turn your
                technological aspirations into reality.
              </q>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
