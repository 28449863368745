import React, { useEffect } from "react";
import { ReactComponent as LocationsSVG } from "../../assets/images/map-locations.svg";
import MapPin from "../../assets/images/map-marker.svg";

const Locations = () => {
  useEffect(() => {
    const Locs = document.querySelectorAll(".locations .address");
    Locs.forEach((loc) => {
      loc.addEventListener("mouseover", () => {
        const pinId = loc.getAttribute("data-id");
        if (pinId) {
          const pin = document.querySelector(pinId) as HTMLElement;
          pin?.classList.add("bounce");
        }
      });
      loc.addEventListener("mouseout", () => {
        const pinId = loc.getAttribute("data-id");
        if (pinId) {
          const pin = document.querySelector(pinId) as HTMLElement;
          pin?.classList.remove("bounce");
        }
      });
    });

    return () => {};
  }, []);

  return (
    <section className="section location-section">
      <div className="container location-container">
        <div className="section-heading text-center mb-5">
          <h5 className="fw-reg mb-2">We are</h5>
          <h3 className="fw-sbo">Located at</h3>
        </div>

        <div className="locations">
          <div className="row align-items-center g-3">
            <div className="col-md-7 ms-0">
              <div className="map-container">
                <div className="pin-container">
                  <img
                    src={MapPin}
                    id="ahmInd"
                    alt="map-pin"
                    className="map-pin ahmedabad"
                  />
                  <img
                    src={MapPin}
                    id="bangInd"
                    alt="map-pin"
                    className="map-pin bangalore"
                  />
                  <img
                    src={MapPin}
                    id="torCA"
                    alt="map-pin"
                    className="map-pin canada"
                  />
                </div>
                <LocationsSVG className="world-map"></LocationsSVG>
              </div>
            </div>
            <div className="col-md-4 offset-md-1 ">
              <div className="address-container">
                <div className="address d-flex" data-id="#ahmInd">
                  <div className="location-icon">
                    <i className="bi bi-pin-map-fill"></i>
                  </div>
                  <div>
                    <h5 className="fw-sbo country">Ahmedabad, India</h5>
                    <p className="location">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://maps.app.goo.gl/8XLXF25GNos4W7rZ8"
                      >
                        617, 6th Floor, Business Hub, Arved Transcube Plaza, 132
                        Feet Ring Road, Ranip, Ahmedabad - 382480 (Gsrtc
                        Busstand)
                      </a>
                    </p>
                  </div>
                </div>
                <div className="address d-flex" data-id="#bangInd">
                  <div className="location-icon">
                    <i className="bi bi-pin-map-fill"></i>
                  </div>
                  <div>
                    <h5 className="fw-sbo country">Bangalore, India</h5>
                    <p className="location">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://maps.app.goo.gl/WqcGiH35oBUQnx5c7"
                      >
                        WeWork Prestige Atlanta, 80 Feet Main Road, Koramangala,
                        1A Block Bangalore, KA - 560034
                      </a>
                    </p>
                  </div>
                </div>
                <div className="address d-flex" data-id="#torCA">
                  <div className="location-icon">
                    <i className="bi bi-pin-map-fill"></i>
                  </div>
                  <div>
                    <h5 className="fw-sbo country">Toronto, ON - Canada</h5>
                    <p className="location">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://maps.app.goo.gl/r9yjJdemioFs61Nd8"
                      >
                        2105-40 high park avenue Toronto, ON M6P 2S1
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* World Map Highlights Active locations */}
        </div>
      </div>
    </section>
  );
};

export default Locations;
