import React from "react";
import RemoteBricksLogo from "../assets/images/RemoteBricksLogos/RemoteBricksLogo-light.svg";
import { ReactComponent as LinkedInIcon } from "../assets/icons/LinkedIn.svg";
import { ReactComponent as ClutchIcon } from "../assets/icons/Clutch.svg";
import { ReactComponent as MediumIcon } from "../assets/icons/Medium.svg";
import { ReactComponent as InstagramIcon } from "../assets/icons/Instagram.svg";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="main-footer">
      <div className="container">
        <div className="row mb-5">
          <div className="col-12">
            <div className="brand-logo-container">
              <img
                src={RemoteBricksLogo}
                className="brand-logo"
                alt="Remotebricks Logo"
              />
            </div>
          </div>
          <div className="col-lg-4">
            <div className="contacts-wrapper">
              <h6 className="fw-med">Contacts</h6>
              <ul>
                <li>
                  <a href="mailto: contact@remotebricks.com">
                    contact@remotebricks.com
                  </a>
                </li>
                <li>
                  <a href="mailto: info@remotebricks.com">
                    info@remotebricks.com
                  </a>
                </li>
                <li>
                  <a href="tel: 080-378 62368">080-378 62368</a>
                </li>
                <li>
                  <a href="tel: 087-489 06886">087-489 06886</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="headquarters-address-wrapper">
              <h6 className="fw-med">Headquarters</h6>
              <p>
                WeWork Prestige Atlanta, 80 Feet Main Road,
                <br />
                Koramangala,
                <br />
                1A Block Bangalore, KA – 560034
              </p>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="social-media-wrapper">
              <h6 className="fw-med">Social Media</h6>
              <ul className="d-flex mb-0 gap-4 justify-content-start">
                <li className="mb-0">
                  <a
                    href="https://in.linkedin.com/company/remotebricks"
                    target="_blank"
                  >
                    <LinkedInIcon height={30} width={30} />
                  </a>
                </li>
                <li className="mb-0">
                  <a
                    href="https://clutch.co/profile/remotebricks#highlights"
                    target="_blank"
                  >
                    <ClutchIcon height={20} width={20} />
                  </a>
                </li>
                <li className="mb-0">
                  <a
                    href="https://www.instagram.com/rmtbricks/"
                    target="_blank"
                  >
                    <InstagramIcon height={30} width={30} />
                  </a>
                </li>
                <li className="mb-0">
                  <a href="">
                    <MediumIcon height={30} width={30} />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="privacy-statment">
        <div className="container">
          <div className="py-sm-4 py-3">
            <div className="row g-2">
              <div className="col-lg-6">
                <div className="copyrights">
                  <p className="mb-0">
                    © 2023-2024 Remotebricks. All rights reserved.
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <ul className="d-flex my-0 gap-4 justify-content-sm-end justify-content-center">
                  <li className="mb-0">
                    <Link to="/">Privercy policy</Link>
                  </li>
                  <li className="mb-0">
                    <Link to="/">Customer support</Link>
                  </li>
                  <li className="mb-0">
                    <Link to="/">Careers</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
