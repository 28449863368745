import React from 'react';
import EarthCanvas from '../../components/EarthCanvas';

const Banner = () => {
    return (
        <section className='section banner-section h-100'>
            <div className="container-fluid">
                <EarthCanvas></EarthCanvas>
                <div className="tag-line-container">
                    <h1 className='tag-line text-gradient'>
                        <span className='fw-sbo'>Connecting talents</span> Globally,
                        <br /> Delivering Innovation<span className='fw-sbo ms-2'>Remotely</span>
                    </h1>
                </div>
            </div>
        </section>
    )
}

export default Banner