import React, { memo } from 'react'

const EarthCanvas = () => {
    return (
        <>
            <canvas id='stars' className='stars-canvas-animation'></canvas>
            <canvas id='planets' className='planets-canvas-animation'></canvas>
            <canvas id="earth" className='earth-canvas-animation'></canvas>
        </>);
}

export default memo(EarthCanvas);