import React from 'react';

const PageNotFound: React.FC = () => {
    return (
        <>
            <div className='page-not-found'>
                <h1>404</h1>
                <p className=''>Page not found !</p>
            </div>
        </>
    );
};

export default PageNotFound;