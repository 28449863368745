import React from "react";
import { ReactComponent as IconCloudEngineering } from "../../assets/icons/servicesSvgs/cloud-engineering.svg";
import { ReactComponent as IconDataAnaytics } from "../../assets/icons/servicesSvgs/data-analytics.svg";
import { ReactComponent as IconDataScienceEngi } from "../../assets/icons/servicesSvgs/data-science-engineering.svg";
import { ReactComponent as IconDigitalMarketing } from "../../assets/icons/servicesSvgs/digital-marketing.svg";
import { ReactComponent as IconFullstackDev } from "../../assets/icons/servicesSvgs/full-stack-dev.svg";
import { ReactComponent as IconMobileDev } from "../../assets/icons/servicesSvgs/mobile-dev.svg";
import { ReactComponent as IconPerfomenceAndQA } from "../../assets/icons/servicesSvgs/perfomance-qa.svg";
import { ReactComponent as IconUXStretegy } from "../../assets/icons/servicesSvgs/ux-stretegy.svg";
import { Link } from "react-router-dom";

const Services = () => {
  return (
    <section className="section services-section h-100">
      <div className="container services-container">
        <div className="section-heading text-center mb-5">
          <h5 className="fw-reg mb-2">Services we offer</h5>
          <h3 className="fw-sbo">End to end Product Life cycle</h3>
        </div>
        <div className="row g-md-4 g-3">
          <div className="col-xl-3 col-lg-4 col-6">
            <div className="service-card-wrapper h-100">
              <div className="card service-card">
                <div className="card-body">
                  <div className="service-title">
                    <div className="service-icon">
                      <IconUXStretegy className="rb-service-icon" />
                    </div>
                    <h4 className="mb-0 fw-sbo">
                      Project Strategy & User experience
                    </h4>
                  </div>
                  <div className="service-description">
                    {/* <h6 className="mb-2 fw-med">Project Strategy & User experience</h6> */}
                    <p>
                      Our project strategy and user experience services focus on
                      creating a cohesive plan that aligns with your business
                      goals while delivering exceptional user experiences. We
                      strategically design and optimize every aspect of your
                      project to ensure intuitive, engaging, and effective
                      interactions that drive success.
                    </p>
                    {/* <p className='view-more-link'><Link to={'/'} className='d-flex align-items-end'>Hire us <i className='bi bi-arrow-right-short fs-5'></i></Link></p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 col-6">
            <div className="service-card-wrapper h-100">
              <div className="card service-card">
                <div className="card-body">
                  <div className="service-title">
                    <div className="service-icon">
                      <IconDataAnaytics className="rb-service-icon" />
                    </div>
                    <h4 className="mb-0 fw-sbo">Data Scraping and analytics</h4>
                  </div>
                  <div className="service-description">
                    {/* <h6 className="mb-2 fw-med">Data Scraping and analytics</h6> */}
                    <p>
                      Our data scraping and analytics services help you gather
                      valuable information from diverse sources and transform it
                      into actionable insights. We efficiently extract data,
                      analyze trends, and provide comprehensive reports to
                      support informed decision-making and drive strategic
                      growth.
                    </p>
                    {/* <p className='view-more-link'><Link to={'/'} className='d-flex align-items-end'>Hire us <i className='bi bi-arrow-right-short fs-5'></i></Link></p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 col-6">
            <div className="service-card-wrapper h-100">
              <div className="card service-card">
                <div className="card-body">
                  <div className="service-title">
                    <div className="service-icon">
                      <IconFullstackDev className="rb-service-icon" />
                    </div>
                    <h4 className="mb-0 fw-sbo">Full stack development</h4>
                  </div>
                  <div className="service-description">
                    {/* <h6 className="mb-2 fw-med">Full stack development</h6> */}
                    <p>
                      Our full-stack development services offer end-to-end
                      solutions, from engaging front-end interfaces to robust
                      back-end systems. We blend technical expertise with
                      innovation to create seamless, user-focused applications
                      that drive your business forward.
                    </p>
                    {/* <p className='view-more-link'><Link to={'/'} className='d-flex align-items-end'>Hire us <i className='bi bi-arrow-right-short fs-5'></i></Link></p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 col-6">
            <div className="service-card-wrapper h-100">
              <div className="card service-card">
                <div className="card-body">
                  <div className="service-title">
                    <div className="service-icon">
                      <IconMobileDev className="rb-service-icon" />
                    </div>
                    <h4 className="mb-0 fw-sbo">Mobile app Development</h4>
                  </div>
                  <div className="service-description">
                    {/* <h6 className="mb-2 fw-med">Mobile app Development</h6> */}
                    <p>
                      Our mobile app development services craft
                      high-performance, user-friendly apps for both iOS and
                      Android. We focus on creating seamless experiences with
                      intuitive interfaces and robust functionality, tailored to
                      meet your business needs and engage your users
                      effectively.
                    </p>
                    {/* <p className='view-more-link'><Link to={'/'} className='d-flex align-items-end'>Hire us <i className='bi bi-arrow-right-short fs-5'></i></Link></p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 col-6">
            <div className="service-card-wrapper h-100">
              <div className="card service-card">
                <div className="card-body">
                  <div className="service-title">
                    <div className="service-icon">
                      <IconDataScienceEngi className="rb-service-icon" />
                    </div>
                    <h4 className="mb-0 fw-sbo">Data science & Engineering</h4>
                  </div>
                  <div className="service-description">
                    {/* <h6 className="mb-2 fw-med">Data science & Engineering</h6> */}
                    <p>
                      Our data science and engineering services harness the
                      power of data to drive informed decision-making and
                      innovation. We specialize in collecting, processing, and
                      analyzing complex data sets, turning them into actionable
                      insights through advanced analytics and machine learning
                      techniques. Our solutions optimize performance and uncover
                      valuable trends to propel your business forward.
                    </p>
                    {/* <p className='view-more-link'><Link to={'/'} className='d-flex align-items-end'>Hire us <i className='bi bi-arrow-right-short fs-5'></i></Link></p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 col-6">
            <div className="service-card-wrapper h-100">
              <div className="card service-card">
                <div className="card-body">
                  <div className="service-title">
                    <div className="service-icon">
                      <IconPerfomenceAndQA className="rb-service-icon" />
                    </div>
                    <h4 className="mb-0 fw-sbo">
                      Performance and Quality analytics{" "}
                    </h4>
                  </div>
                  <div className="service-description">
                    {/* <h6 className="mb-2 fw-med">Performance and Quality analytics </h6> */}
                    <p>
                      Our end-to-end QA services ensure comprehensive quality
                      assurance throughout your project. From initial testing to
                      final deployment, we rigorously evaluate every aspect to
                      identify and resolve issues, guaranteeing a flawless user
                      experience and high performance.
                    </p>
                    {/* <p className='view-more-link'><Link to={'/'} className='d-flex align-items-end'>Hire us <i className='bi bi-arrow-right-short fs-5'></i></Link></p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 col-6">
            <div className="service-card-wrapper h-100">
              <div className="card service-card">
                <div className="card-body">
                  <div className="service-title">
                    <div className="service-icon">
                      <IconDigitalMarketing className="rb-service-icon" />
                    </div>
                    <h4 className="mb-0 fw-sbo">Digital Marketing</h4>
                  </div>
                  <div className="service-description">
                    {/* <h6 className="mb-2 fw-med">Digital Marketing</h6> */}
                    <p>
                      Our digital marketing services are designed to elevate
                      your online presence and drive measurable results. We
                      create targeted strategies across various channels—such as
                      SEO, social media, email, and paid advertising—to boost
                      engagement, attract customers, and enhance brand
                      visibility. Let us help you connect with your audience and
                      achieve your marketing goals.
                    </p>
                    {/* <p className='view-more-link'><Link to={'/'} className='d-flex align-items-end'>Hire us <i className='bi bi-arrow-right-short fs-5'></i></Link></p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 col-6">
            <div className="service-card-wrapper h-100">
              <div className="card service-card">
                <div className="card-body">
                  <div className="service-title">
                    <div className="service-icon">
                      <IconCloudEngineering className="rb-service-icon" />
                    </div>
                    <h4 className="mb-0 fw-sbo">Cloud engineering</h4>
                  </div>
                  <div className="service-description">
                    {/* <h6 className="mb-2 fw-med">Cloud engineering</h6> */}
                    <p>
                      Our cloud engineering services deliver scalable and secure
                      cloud solutions tailored to your business needs. We
                      design, deploy, and manage cloud infrastructure to enhance
                      performance, reliability, and flexibility, enabling you to
                      focus on growth while we handle the technical
                      complexities.
                    </p>
                    {/* <p className='view-more-link'><Link to={'/'} className='d-flex align-items-end'>Hire us <i className='bi bi-arrow-right-short fs-5'></i></Link></p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
