import React, { useEffect } from "react";

import Banner from "./Banner";
import Services from "./Services";
import TechStack from "./TechStack";
import Statistics from "./Statistics";
import Clients from "./Clients";
import Locations from "./Locations";

const Home = () => {
  return (
    <div className="home-page">
      {/* Section Banner */}
      <Banner></Banner>

      {/* Section Services */}
      <Services></Services>

      {/* Section Counts of Clients */}
      <Clients></Clients>

      {/* Section Tech Stacks */}
      <TechStack></TechStack>

      {/* Section Counts of statistics */}
      <Statistics></Statistics>

      {/* Section Locations */}
      <Locations></Locations>
    </div>
  );
};

export default Home;
