import React, { Suspense } from "react";
import { createBrowserRouter } from "react-router-dom";

import PageNotFound from "../pages/PageNotFound";
import Home from "../pages/Home/HomePage";
import App from "../App";
import About from "../pages/About/About";

/* App Router Configuration */
const AppRouter = createBrowserRouter([
  /* Pages */
  {
    path: "",
    element: <App />,
    /* All the pages falls under the APP because we used APP as Root Layout and ALL links and routes configured under the app */
    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: "aboutus",
        element: (
          <Suspense
            fallback={
              <>
                <h1>Loading...</h1>
              </>
            }
          >
            <About />
          </Suspense>
        ),
      },
      {
        path: "case-studies",
        element: (
          <Suspense
            fallback={
              <>
                <h1>Loading...</h1>
              </>
            }
          >
            {/* Lazy-loded component */}
          </Suspense>
        ),
      },
    ],
  },

  /* 404 Not found */
  {
    path: "*",
    element: <PageNotFound />,
  },
]);

export default AppRouter;
