import React, { useEffect, useRef, useState } from "react";
import "./App.scss";

import Header from "./components/Header";
import Footer from "./components/Footer";
import { Outlet } from "react-router-dom";

// import OuterCircle from './assets/images/RemotebricksLoading/outer-circle.png'
// import InnerCircle from './assets/images/RemotebricksLoading/inner-circle.png'
// import LowerPoint from './assets/images/RemotebricksLoading/lower-point.png'
// import MiddlePoint from './assets/images/RemotebricksLoading/middle-point.png'
// import HigherPoint from './assets/images/RemotebricksLoading/higher-point.png'
import { ReactComponent as RemoteBricksAnimationLogo } from "./assets/images/RemotebricksLoading/animation-logo.svg";

function App() {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isLoadingFade, setLoadingFade] = useState<boolean>(false);
  const loadingTimeout = useRef<NodeJS.Timeout>();
  const loadingFadeOut = useRef<NodeJS.Timeout>();

  useEffect(() => {
    const favicon = document.querySelector(
      'link[rel="icon"]'
    ) as HTMLLinkElement;

    if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
      favicon.href = "RemoteBricksIcon-light.svg";
    } else {
      favicon.href = "RemoteBricksIcon-dark.svg";
    }

    loadingTimeout.current = setTimeout(() => {
      setLoading(false);
      document.getElementsByTagName("html")[0].scrollTop = 0;
      // document.getElementsByTagName('html')[0].scrollTo(0, 0);
      document.body.classList.remove("app-loading");
    }, 2300);

    loadingFadeOut.current = setTimeout(() => {
      setLoadingFade(true);
    }, 2000);

    return () => {
      if (loadingTimeout.current) {
        clearTimeout(loadingTimeout.current);
      }
      if (loadingFadeOut.current) {
        clearTimeout(loadingFadeOut.current);
      }
    };
  }, []);

  return (
    <>
      {isLoading && (
        <>
          <div className={`loading ${isLoadingFade ? "fade-out" : ""}`}>
            <div className="loading-wrapper">
              <RemoteBricksAnimationLogo className="animation-logo" />
            </div>
          </div>
        </>
      )}

      {/* {!isLoading && ( */}
      <div className={`remote-wrapper ${isLoading ? "bricks-loading" : ""}`}>
        <Header></Header>
        <div className="page-container">
          <Outlet></Outlet>
        </div>
        <Footer></Footer>
      </div>
      {/* )} */}
    </>
  );
}

export default App;
